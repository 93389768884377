<template>
  <HomeContentLayout>
    <Layout>
      <Sider  hide-trigger :collapsed-width="64"  class="siderCls" v-if="isShow" style="background:#fff,width: 190px;min-width: 190px;max-width:190px;">          
        <el-tree :data="treeData" 
                  :props="defaultProps" 
                  @node-click="handleNodeClick" 
                  node-key="id"  
                  :default-expanded-keys="['19665']"                     
                  :style="{height: scrollerHeight,overflow:'auto' }"
                  highlight-current
                  :filter-node-method="filterNode"
                  ref="eltree"
                  :expand-on-click-node="false">
            <template v-slot="{node,data}">                                     
                <span v-if="data.id ==='19665' "  class="iconfont icon-caiwuguanli" >
                  <span class="nodeLabel">{{ node.label}} </span>
                </span>                  
                <span v-else class="el-icon-caret-right" > 
                    <span class="nodeLabel">{{ node.label}} </span>
                </span>
            </template>
        </el-tree>        
      </Sider>
      <Content>
          <div class="siderCls" >
            <Icon type="ios-menu-outline" class="md-menu" size="26" @click.native="toggleLeft"></Icon>
            <el-button @click="addRec()" type="text" icon="el-icon-plus" style="margin-right:20px;"> 新增记录</el-button>           
            <span style="margin-right:6px;font-size:12px;">记录状态</span>
            <el-radio-group v-model="stfgSet"   size="small" @change="stfgChange">
                <el-radio border label="ALL"  >所有</el-radio>
                <el-radio border label="N" >待审核</el-radio>
                <el-radio border label="Y" >已审核</el-radio>
            </el-radio-group>
            <span style=" margin-left:20px;font-size:12px;">字段查询</span>        
            <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="queryData" size="small" style="width:350px;margin-left:10px;vertical-align:center">
              <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px;">
                  <span v-for="item in crcyCol" :key='item.key'>
                      <el-option :label="item.title" :value="item.key"></el-option>
                  </span>
              </el-select>
            </el-input> 
            <div>
          <el-table  :data="crcyData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              highlight-current-row
              style="overflow:auto;margin-top:1px;"
              @row-click="matClick" > 
              <span v-for="(item,index ) in crcyCol" :key="index" >
                <el-table-column  
                    v-if="item.thide==='N'"
                    :prop="item.key"
 
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
              </span>
              <el-table-column
                fixed="right"
                label="操作"
                align="center"
                width="130">
                <template slot-scope="scope">
                    <el-button size="small"  v-if="scope.row['STFG']=='N'" type="text" icon="el-icon-edit" @click="editRec(scope.row,scope.$index)">编辑</el-button>
                    <el-button size="small" v-if="scope.row['STFG']=='N'"  type="text" icon="el-icon-check" @click="cfmRec(scope.row,'Y')">审核</el-button>
                    <el-button size="small" v-if="scope.row['STFG']=='Y'" type="text" icon="el-icon-close" @click="cfmRec(scope.row,'N')">反审核</el-button>  
                </template>
              </el-table-column>                       
          </el-table>
          </div>
          <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden">
                <div style="float: left;">
                    <Page :total="dataCount" :page-size="pageSize" show-total :current="1" @on-change="changePage"></Page>
                </div>
          </div> 
        </div>
      </Content>
        <el-dialog  :visible.sync="addModel" width="1100px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{perFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div>
            <div slot="footer" style="height:34px;">
                <el-image fit="scale-down"  style="width:135px;  margin-left:20px;height:35px;vertical-align:middle" :src="imgSrc" > </el-image>
                    <el-button type="info" @click="addModel=false" size="small"  icon="iconfont icon-shanchu">  取消</el-button>
                    <el-button v-if="saveFlag===true" @click="modal_ok" size="small" type="primary" style="margin-left:30px;" icon="iconfont icon-baocun"  > 保存 </el-button>       
            </div>
            <el-card v-for="(gpitem,index) in perGpArr" :key="index" style="margin-bottom:15px;">
                <Form :model="perRow" :label-width="110" ref="perForm"  label-position="right" inline  :rules="ruleValidate">  
                    <span v-for="(item,index) in gpitem.gplist" :key="index" >
                        <FormItem  :label="item.FLDNM"  :class=" 'item-width'" :prop="item.FLDNO"  v-if="item.FHIDE==='N'">                  
                            <el-date-picker type="date"  v-if="item.EDCSS==='date'" v-model="perRow[item.FLDNO]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-input-number :precision='4'  v-else-if="item.EDCSS==='number'" v-model="perRow[item.FLDNO]" style="width:100%" ></el-input-number>   
                            <el-select   v-else-if="item.FLDNO==='BSCRCY'" v-model="perRow[item.FLDNO]" style="width:100%" >
                                <el-option  v-for="item in crcyArr" :key="item.CODE" :value="item.CODE" :label="item.CNAME" ></el-option>
                            </el-select>
                            <el-checkbox border v-else-if="item.EDCSS==='checkbox'" true-label="Y"  false-label="N" v-model="perRow[item.FLDNO]" style="width:100%"></el-checkbox> 
                            <el-input  type="text" v-else :disabled="item.DISABLED==='Y'?true:false "  v-model="perRow[item.FLDNO]"></el-input>
                        </FormItem>
                    </span>
                </Form>
            </el-card>
        </el-dialog> 
    </Layout>
  </HomeContentLayout>
</template>
<script>
    import { getTabColOrData } from '../../api/user';
    import { filterArrObj } from '../../api/Tools';
    import {  paramBase, getDate,baseCascade,paramData} from '../../api/Select'
 
    export default {
    name:"sys_crcy",
    data() {
        return {
            loading:false,
            dataCount:0,
            stfgSet:'ALL',    
            addModel:false,
            perFlag:'create',
            isShow:true,
            ruleValidate: {
               "BSCRCY":[{"required":true,"message":"锚定货币不能为空","trigger":"change"}],
               "EXRT":[{"required":true,"message":"兑换率不能为空","trigger":"change"}]
            }, 
            type:'create',
            imgSrc:'',
            LANG:'',
            uploadUrl:this.$store.state.baseUrl +'auth/login/uploadImg',
            treeData:[] ,//  树数据
            crcyCol:[], //crcy列（表格隐藏列）
            label:'', //货币名称
            perRow:{}, //价格行
            selparam:'IDSEQ',// select 绑定值
            textparam:'', // input 绑定值
            perGpArr:[], // 联络人表单分组信息
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            saveFlag:true,
            nodeId:'19665',
            crcyData:[] , // crcy数据
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_many:this.$store.state.manyUrl , //api请求路径   
 
            v_formurl:this.$store.state.baseUrl+'user/getFormGroup', //多记录查询 like模式  
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            input_hold:'请输入内容...',
            crcyArr:[], //货币
            rowIndex:'-1',
            allData:[] , //保存产品所有数据
            pageSize:25,
            pageNum:1,
 
        }
    },
    watch: {
    },
    components: {
 
    },
    computed: {
        scrollerHeight: function() {
            return (window.innerHeight - 150) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 160) + 'px';
        }, 
    },
    mounted () {       
        //货币种类
        paramBase('19665','v_sys_base').then((res)=>{
            this.crcyArr =res.data.result
        }); 
        // 资料分组 
        getTabColOrData(this.v_formurl,this.v_username,this.v_password,'v_sys_form_fld','idseq','30','gpnm,gpsort','','','',' order by gpsort').then((res) => {
            this.perGpArr = res.data.result             
        });  
    },
    created () {  
      this.getData()     
      this.getTabCol() //产品表列字段
      
    },
    methods: { 
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.pageSize;
            var _end = index * this.pageSize;
            this.crcyData = this.allData.slice(_start,_end);
        },  
        //状态查询
        stfgChange(){
            if (this.stfgSet==='ALL'){
                this.getCrcyData(this.nodeId,'stfg','%' )
            }else{
                this.getCrcyData(this.nodeId,'stfg',this.stfgSet)  
            }
        },  
        queryData(){
            this.getCrcyData(this.nodeId,this.selparam,this.textparam)
        },
 
        //联系人  保存
        perClick(row){
            //js 对象的赋值是引用赋值，传递的是地址，如果copy出一份进行值的改变，就会引起被copy值一起改变，这时要采用这样
            //json.parse()用于将一个json字符串转换为对象，并返回转换后的对象
            this.perRow =JSON.parse(JSON.stringify(row))  //深拷贝
        },
        //保存记录
        modal_ok(){           
            if(!this.perRow.BSCRCY){
                this.$Message.error('锚定货币不能为空' )                
            }else if(!this.perRow.EXRT){
                this.$Message.error('兑换率不能为空' )                
            }else{
                this.$axios({
                    method: 'post',
                    url:this.$store.state.baseUrl+'sysprivs/tableBodySave?username='+this.v_username+'&password='+this.v_password,
                    data: {p_json:JSON.stringify(this.perRow), p_table:'BS_CRCY',p_idseq:this.nodeId},
                }).then(res=>{
                    this.addModel =false
                    this.getCrcyData(this.nodeId,this.selparam,this.textparam )
                })
            }          
        },
        toggleLeft(){
            this.isShow =!this.isShow
        },
        matOk(){

        },
        // 变更状态记录
        cfmRec(row,val){
          if (row.STFGNM==='审核' && val==='Y'){
            this.$Message.error('此记录已是审核状态' ) 
          }else if (row.STFGNM==='未审核' && val==='N'){
            this.$Message.error('此记录已是未审核状态' ) 
          }else {
            let v_comm='',v_date=''
            if (val==='Y'){
                v_comm ='审核'
                v_date =getDate('full')
            }else{
                v_comm ='未审核'
            }
            this.$Modal.confirm({
                title: '提示',
                content: '<p>你确定要'+v_comm+'此行记录吗</p>',
                onOk: () => {
                    var vm=this;
                    this.$axios({
                        method: 'post',
                        url:vm.$store.state.baseUrl+'user/updateRec?username='+this.v_username+'&password='+this.v_password,
                        data: {p_key:'idseq',p_value:row.IDSEQ,p_key2:'lstseq',p_value2:row.LSTSEQ,p_table:'BS_crcy',p_cond:' set stfg=\''+val+'\',cfmdttm=\''+v_date+'\''},
                    }).then(res=>{      
                        if(res.data.code =='200')  {           
                            Object.assign(row,{STFGNM:v_comm,CFMDTTM:v_date,STFG:val})
                        }else{
                            this.$Message.error(res.data.result)
                        }        
                    }) 
                }
            });
          }
        },
        //修改记录
        editRec(row){
            this.addModel =true
            if (row['STFGNM']==='审核')  {  
                this.saveFlag=false
                this.imgSrc='/assets/basedict/confirm.png'
            }else if (row['STFGNM']==='作废')  {  
                this.imgSrc='/assets/basedict/cancel.png'
            }else{
                this.imgSrc='/assets/basedict/no_confirm.png'
                this.saveFlag=true
            }
        },
        //新增记录
        addRec(){
          if (this.nodeId==='19665'){
             this.$Message.error('请选择币种后新增记录')
          }else{
            //如对应币种存在未审核记录时不能新增
            this.$axios({
                method: 'post',
                url:this.$store.state.baseUrl+'user/getRecordCnt?username='+this.v_username+'&password='+this.v_password,
                data: {p_key:'idseq',p_value:this.nodeId, p_table:'v_BS_crcy',p_cond:" and stfg= 'N' "},
            }).then(res=>{      
                if(res.data>0)  {           
                    //this.$Message.error('记录未审核不能新增')
                    this.$alert('币种下有未审核记录,不能新增', '提示', {
                                confirmButtonText: '确定',
                                type:'info',
                                showClose:false}
                    )
                }else{
                    this.addModel =true
                    this.perFlag ='create'
                    this.perRow ={'IDSEQ':this.nodeId,CRCYNM:this.label,CRCY:this.nodeId}
                    this.saveFlag =true
                    this.imgSrc='/assets/basedict/no_confirm.png'
                }        
            }) 
            
          }
        },
 
        //查询currency数据
        getCrcyData(nodeid,key,value){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_CRCY','','','','','','',' and instr(IDSEQ,'+nodeid+')>0 and '+key+' like \'%'+value+'%\' order by adddttm desc').then((res) => {
              this.crcyData =res.data     
            })
        },
 
        // 查询界面单 行事件
        matClick(row){
            this.perRow =JSON.parse(JSON.stringify(row))
        },
        elchange(val){  // 这个val 就是 el-select 的 value
            let obj ={}
            obj =this.crcyCol.find(item=>{
                return item.key===val
            })
            if (obj)
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //获取表列头字段
        getTabCol(){
            var vm=this
            //货币列
            getTabColOrData(vm.v_many,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','30','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                if (res.data.code==='200'){
                    for (let k=0; k<res.data.result.length; k++){
                        vm.crcyCol.push({ //向数组的开头 添加序列号
                            title: res.data.result[k].title,
                            key: res.data.result[k].key,
                            align: res.data.result[k].align,
                            width: res.data.result[k].width,
                            thide: res.data.result[k].THIDE,
                            fhide: res.data.result[k].FHIDE,
                            sortable: res.data.result[k].sortable?true:false,
                        })
                    }
                }else{
                   this.$Message.info('系统断开连接，请重新登录') 
                }
            });
        },
        handleNodeClick(data){
            this.nodeId =data.id
            this.label =data.label
            this.getCrcyData(data.id,this.selparam,this.textparam)
        }  ,
        //节点过滤
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 获取 树形控件数据
        getData() {  
            var vm=this;     
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_table:'V_CRCY_TREE'}
            }).then(function(res){                  
                vm.treeData =res.data;   
                if(res.data.length > 0 ){
                    //'nextTick()' 下次dom更新时触发回调函数
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(vm.nodeId)
                    })
                }            
            }).catch(function(err){
                vm.$Message.error('error:'+err )
            })                              
        },
    },
 }
</script>

<style scoped lang="less">
    ::v-deep .el-table {
        th {
            padding: 0px ;
        }
        thead{
            height: 15px !important;
            color:rgb(71, 70, 70);
            font-size:12px;
        }
        td {
            padding: 3px ;
            font-size:11px;
        }   
    }
    .el-cascader-menu{
        height:400px;
        width:100%;
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
</style> 
<style lang="less">
    .md-menu{margin-right: 15px;cursor: pointer}
    .md-menu:hover{background-color:#dae9f7 }

    .row-width  {
        width: 98.5%;
    }
    .el-card__header{
        //height:35px;
        vertical-align: middle;
    }
 
 
</style>
